import React from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import { userLoggedOut } from '../../../redux/actions';
import { logout } from '../../../lib/api';
import lylliLogo from '../../../images/lylli-logo.svg';

import NavText from './NavText';

import ExternalNavLink from './ExternalNavLink';
import './Navbar.css';
import { getSessionId } from '../../../lib/storage';

import tr from '../../../../translations';
const {REGION, SWE_SITE_URL, DEU_SITE_URL} = require("../../../../config");

NavText.propTypes = { to: PropTypes.bool };
const MyNavbar = ({ 
  search, 
  overlay, 
  userIsLoggedIn,
  actionUserLoggedOut 
}) => {

  const handleLogOut = async(evt) => {
    evt.preventDefault();

    try{

      await logout(getSessionId());
    } catch (error) {
      console.error(error)
    } finally {
      actionUserLoggedOut();
      window.location.reload();
    }
  };

  return (
    <>
      <Navbar variant="dark" collapseOnSelect fixed="top" expand="md">
        <Container>
          <Navbar.Brand>
            <ExternalNavLink url={REGION === "SWE" ? `${SWE_SITE_URL}${search || ''}` : `${DEU_SITE_URL}${search || ''}`} noPadding>
              <img alt="Lylli" src={lylliLogo} className="logo logo-snok" />
            </ExternalNavLink>
          </Navbar.Brand>
          {/*<NavButton*/}
          {/*  withClasses*/}
          {/*  className="fix-cta-mobile"*/}
          {/*  to={ctaUrl || `https://www.lylli.se/konto/skapa-konto${search}`}*/}
          {/*>*/}
          {/*  {ctaText || defaultTexts.ctaText}*/}
          {/*</NavButton>*/}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto" />
            <Nav>
              {/*<NavButton className="hidden-up-to-sm" secondary to={accountHost}>*/}
              {/*  Mina sidor*/}
              {/*</NavButton>*/}
              {/*<NavButton*/}
              {/*  className="hidden-up-to-sm"*/}
              {/*  to={ctaUrl || `https://www.lylli.se/konto/skapa-konto${search}`}*/}
              {/*>*/}
              {/*  {ctaText || defaultTexts.ctaText}*/}
              {/*</NavButton>*/}
            </Nav>
          </Navbar.Collapse>
          {userIsLoggedIn === true ? 
            <button
              className='logout-button'
              onClick={handleLogOut}>
              {tr('logout')}
            </button> :
            <></>
          }
        </Container>
      </Navbar>
      {overlay}
    </>
  );
};

export default connect(
  ({ 
    user: { 
      userIsLoaded, 
      userIsLoggedIn },
  }) => ({
    userIsLoaded,
    userIsLoggedIn,
  }),
  {
    actionUserLoggedOut: userLoggedOut
  }
)(MyNavbar);
