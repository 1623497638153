import { removeSessionId, removeLoginToken } from '../lib/storage';

import { USER } from './actionTypes';

export const userNotLoggedIn = () => ({
  type: USER.LOADED,
});

export const userLoggedOut = () => {
  removeSessionId();
  removeLoginToken();
  return {
    type: USER.LOGGED_OUT,
    payload: null,
  };
};

export const userDataLoaded = (userData) => ({
  type: USER.DATA_LOADED,
  payload: userData,
});

export const userEmailUpdated = (email) => ({
  type: USER.EMAIL_UPDATED,
  payload: email,
});
