import PropTypes from 'prop-types';
import React from 'react';
import Container from 'react-bootstrap/Container';

import { Colors } from '../../layout';

import './Section.css';

const Section = ({ children, className, bgColor, show, style }) =>
  show ? (
    <section
      className={className}
      style={{
        backgroundColor: Colors[bgColor],
        ...style,
      }}
    >
      <Container>{children}</Container>
    </section>
  ) : null;

Section.propTypes = {
  bgColor: PropTypes.oneOf(Object.keys(Colors)),
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  show: PropTypes.bool,
};

Section.defaultProps = {
  bgColor: 'white',
  className: '',
  style: {},
  show: true,
};

export default Section;
