import React from 'react';
import Nav from 'react-bootstrap/Nav';

const NavLink = ({ children, className, noPadding, url }) => (
  <Nav.Item>
    <a href={url} key={`${url}-${Date.now()}`} className={`nav-link ${noPadding || ''} ${className || ''}`}>
      {children}
    </a>
  </Nav.Item>
);

export default NavLink;
