import PropTypes from 'prop-types';
import ReactButton from 'react-bootstrap/Button';
import React from 'react';

import './ButtonOld.css';

const ButtonOld = ({ center, className, children, ...props }) => (
  <ReactButton
    size="lg"
    className={`action-button margin-top ${center && 'center'} ${className ? ` ${className}` : ''}`}
    {...props}
  >
    {children}
  </ReactButton>
);

ButtonOld.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ButtonOld.defaultProps = {
  center: false,
};

export default ButtonOld;
