import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ICON, IS_PROD } from '../../../config';
import {
  getSessionId,
  removeLoginToken,
  removeSessionId,
  setLoginToken,
  setSessionId,
} from '../../lib/storage';
import { createSessionIdFromLoginToken, me } from '../../lib/api';
import { userNotLoggedIn, userDataLoaded } from '../../redux/actions';
import {
  getUtmTags,
  sendTrackingEvent,
  sendConsentSelectionEvent,
  sendTrackingEventOnConsent,
} from '../../lib/utils';

import { Header, Footer } from './';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../layout.css';
import './Base.css';

class Base extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    headerColor: PropTypes.string,
    hideHeader: PropTypes.bool,
    hideFooter: PropTypes.bool,
    hideLogoUntilY: PropTypes.number,
  };

  static defaultProps = {
    headerColor: 'purple',
    hideHeader: false,
    hideFooter: false,
  };

  state = {
    y: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.sendConsentSelectionEvent = sendConsentSelectionEvent;
    window.sendTrackingEventOnConsent = sendTrackingEventOnConsent;
    this.setState({ y: window.scrollY });
    const { userIsLoaded, actionUserNotLoggedIn, actionUserDataLoaded } = this.props;
    getUtmTags();
    let sessionId = getSessionId();
    const urlParams = new URLSearchParams(window.location.search);

    const loginToken = urlParams.get('lt');
    const isProfilePage = window.location.pathname.startsWith('/profile');
    // const isLoginPage = window.location.pathname === '/';

    if (isProfilePage && loginToken) {
      console.log('has loginToken');
      createSessionIdFromLoginToken(loginToken)
        .then(({ _id }) => {
          setSessionId(_id);
          setLoginToken(loginToken);
          window.location.replace(window.location.pathname);
        })
        .catch(() => {
          window.location.href = '/';
        });
    }

    sendTrackingEvent('view_web_page');

    if (!userIsLoaded) {
      if (!getSessionId()) {
        if (isProfilePage && loginToken) {
          // we ignore this case
        } else {
          actionUserNotLoggedIn();
        }
        return;
      }

      me(sessionId)
        .then((data) => {
          actionUserDataLoaded(data);
        })
        .catch((ex) => {
          if (ex.statusCode === 401) {
            removeSessionId();
            removeLoginToken();
          } else {
            console.error(ex);
          }
          actionUserNotLoggedIn();
        });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      y: window.scrollY,
    });
  };

  render() {
    const { hideFooter, hideHeader, hideLogoUntilY, ...headerProps } = this.props;
    const { headerColor } = headerProps;
    const { y } = this.state;
    return (
      <>
        {!hideHeader && (
          <Header {...{ ...headerProps, hideLogo: hideLogoUntilY && y < hideLogoUntilY }} />
        )}
        <main className={`bg-${headerColor}`}>{this.props.children}</main>
        {!hideFooter && <Footer />}
        {!IS_PROD && (
          <div className="env-indication">
            <span className="region">{ICON}</span>
          </div>
        )}
      </>
    );
  }
}

export default connect(({ user: { userIsLoaded } }) => ({ userIsLoaded }), {
  actionUserNotLoggedIn: userNotLoggedIn,
  actionUserDataLoaded: userDataLoaded,
})(Base);
