import PropTypes from 'prop-types';
import React from 'react';

import ReactButton from 'react-bootstrap/Button';
import { Link } from 'gatsby';

import './Button.css';

const COLORS = [
  'purple',
  'purple-hover',
  'purple-medium',
  'purple-light',
  'black',
  'grey',
  'grey-light',
  'grey-light-light',
  'white',
  'blue-dark',
  'blue-dark-dark',
  'blue',
  'blue-light',
  'red',
  'green',
];

const Button = ({
  bg,
  block,
  center,
  children,
  className,
  disabled,
  fg,
  half,
  secondary,
  small,
  table,
  to,
  ...props
}) => {
  const compiledClasses = [
    'boksnok-button',
    block ? 'block' : '',
    center ? 'center' : '',
    half ? 'half' : '',
    small ? 'small' : '',
    className || '',
  ]
    .filter(Boolean)
    .join(' ');
  const style = {
    backgroundColor: `var(--color-${secondary ? fg : bg})`,
    border: secondary? `2px var(--color-${bg}) solid` : 'unset',
    color: `var(--color-${secondary ? bg : fg})`,
    ...(table ? { display: 'table' } : {}),
  };

  if (to) {
    if (to.startsWith('https://')) {
      return (
        <a href={to} key={`${to}-${Date.now()}`} className={compiledClasses} style={style}>
          {children}
        </a>
      );
    }
    return (
      <Link key={`${to}-${Date.now()}`} to={to} className={compiledClasses} style={style}>
        {children}
      </Link>
    );
  }

  return (
    <ReactButton disabled={disabled} className={compiledClasses} size="lg" style={style} {...props}>
      {children}
    </ReactButton>
  );
};

Button.propTypes = {
  bg: PropTypes.oneOf(COLORS),
  block: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  fg: PropTypes.oneOf(COLORS),
  half: PropTypes.bool,
  small: PropTypes.bool,
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  onClick: PropTypes.func,
};

Button.defaultProps = {
  bg: 'red',
  block: false,
  center: false,
  disabled: false,
  fg: 'white',
  half: false,
  small: false,
};

export default Button;
