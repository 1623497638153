import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FooterColumn from './FooterColumn';

import './Footer.css';
import iconFB from '../../../images/facebook.svg';
import iconIN from '../../../images/instagram.svg';
import iconLI from '../../../images/LinkedIn.svg'
import AppLogo from '../AppLogo';
import deuIcon from '../../../images/DEU.svg';
import sweIcon from '../../../images/SE.svg';
import lylliLogo from '../../../images/lylli-logo-footer.svg';

import applePay from '../../../images/applepay.svg';
import googlePay from '../../../images/googlepay.svg';
import klarna from '../../../images/klarna.svg';
import mastercard from '../../../images/mastercard.svg';
import visa from '../../../images/visa.svg';
import paypal from '../../../images/paypal.svg';

const {REGION, SWE_SITE_URL, DEU_SITE_URL} = require('../../../../config');

const PAYMENTS = {
  SWE: [
    {type: "Apple Pay", img: applePay},
    {type: "Google Pay", img: googlePay},
    {type: "Klarna", img: klarna},
    {type: "Mastercard", img: mastercard},
    {type: "Visa", img: visa},
  ],
  DEU: [
    {type: "Apple Pay", img: applePay},
    {type: "Google Pay", img: googlePay},
    {type: "Klarna", img: klarna},
    {type: "Mastercard", img: mastercard},
    {type: "Visa", img: visa},
    {type: "PayPal", img: paypal}
  ],
};

const PayIcons = () => (
  PAYMENTS[REGION].map((pay, i) => 
    <img src={pay.img} alt={pay.type} className='pay-icon' key={i}/>
  )
);

const TextLink = ({ children, to, external }) => (
  <p>
    <a href={external ? `${to}` : (REGION === "SWE" ? `${SWE_SITE_URL}${to}` : `${DEU_SITE_URL}${to}`)}>{children}</a>
  </p>
);

const ImgLink = ({ alt, src, to, classname, text }) => (
  <a href={to} className={classname? classname : ""} target="_blank" rel="noopener noreferrer" >
    <img alt={alt} src={src} />
    {text ? <span>{text}</span> : ""}
  </a>
);

export default () => {
  return (
      <footer style={typeof window !== 'undefined' ? (
        window.location.pathname === '/' ? 
        {backgroundColor: "var(--color-lylli-light-purple)"} : {backgroundColor: "var(--color-lylli-white)"} 
        ) : {backgroundColor: "var(--color-lylli-light-purple)"} }>
        <div className='footer-container'>
          <img className='footer-logo' alt='Lylli' src={lylliLogo}></img>

          {REGION==='SWE' ?
            // ------ Swedish footer ------
            <Container className='footer-rows'>
              <Row className="footer-row">
                <Col lg={6} md={12} className='footer-stores-col'>
                  <AppLogo trackerName="footer" appType='APPLE' region={REGION} theme="white"/>
                  <AppLogo trackerName="footer" appType='GOOGLE' region={REGION} theme="white"/>
                </Col>
                <hr className="hide-desktop"/>
                <Col className="footer-col-wrapper" lg={6} md={12}>
                  <Row>
                    <FooterColumn title="Upptäck Lylli" lg={4} md={4} sm={6} xs={6}>
                      <TextLink to='/sok-titlar'>
                        Sök titlar
                      </TextLink>
                      <TextLink to="/konto/skapa-konto">
                        Skapa konto
                      </TextLink>
                      <TextLink external to="/">
                        Mina sidor
                      </TextLink>
                      <TextLink to="/laslust">
                        Tips och inspiration
                      </TextLink>
                      <TextLink external to="https://shop.lylli.se/">
                        Lylli Shop
                      </TextLink>
                    </FooterColumn>

                    <Col className="footer-col" lg={4} md={4} sm={6} xs={6}>
                      <FooterColumn title="Lär känna oss" lg={12} sm={12} xs={12}>
                        <TextLink to='/om-oss'>
                          Om oss
                        </TextLink>
                        <TextLink external to='https://press.lylli.se'>
                          Press
                        </TextLink>
                        <TextLink to='/jobs'>
                          Karriär
                        </TextLink>
                      </FooterColumn>

                      <FooterColumn title="Följ oss" lg={12} sm={12} xs={12}>
                        <div className='footer-socials-container'>
                          <ImgLink to="https://facebook.com/lylli.appen" classname="social-logo-link" src={iconFB}/>
                          <ImgLink to="https://www.instagram.com/lylli_se/" classname="social-logo-link" src={iconIN}/>
                          <ImgLink to="https://www.linkedin.com/company/lylli/" classname="social-logo-link" src={iconLI}/>
                        </div>
                      </FooterColumn>  
                    </Col>

                    <Col className="footer-col" lg={4} md={4} sm={6} xs={6}>
                      <FooterColumn title="Support" lg={12} sm={12} xs={12}>
                        <TextLink to='/vanliga-fragor'>
                          Vanliga frågor
                        </TextLink>
                        <TextLink external to='mailto:kontakt@lylli.se'>
                          Mejla oss
                        </TextLink>
                      </FooterColumn>
                      <FooterColumn title="Språk" lg={12} sm={12} xs={12}>
                        {REGION === 'SWE' ? 
                          <ImgLink to={DEU_SITE_URL} classname="flag-icon-link" src={deuIcon} text="Tyska"/> :
                          <ImgLink to={SWE_SITE_URL} classname="flag-icon-link" src={sweIcon} text="Svenska"/>
                        }
                      </FooterColumn> 
                    </Col>            
                  </Row>
                </Col>
              </Row>
              <hr/>
              <Row className="footer-row">
                <Col className='footer-col-wrapper' lg={6} md={12} sm={12}>
                  <div className='footer-policy-column'>
                    <TextLink to='/allmanna-villkor'>
                      Allmänna villkor
                    </TextLink>
                    <div className='vl'></div> 
                    <TextLink to='/kakor'>
                      Kakor
                    </TextLink>
                    <div className='vl'></div> 
                    <TextLink to='/personuppgiftspolicy'>
                      Personuppgiftspolicy
                    </TextLink>
                    <div className='vl'></div> 
                    <p>
                      @ 2024 Boksnok AB
                    </p>
                  </div>
                </Col>

                <Col className='footer-col-wrapper' lg={6} md={6} sm={12}>
                  <div className='footer-payment-column'>
                    <PayIcons />
                  </div>
                </Col>
              </Row>
            </Container>

            :

            // ------ German footer ------
            <Container className='footer-rows'>
              <Row className="footer-row">
                <Col lg={6} md={12} className='footer-stores-col'>
                  <AppLogo trackerName="footer" appType='APPLE' region={REGION} theme="white"/>
                  <AppLogo trackerName="footer" appType='GOOGLE' region={REGION} theme="white"/>
                  <AppLogo trackerName="footer" appType='AMAZON' region={REGION} theme="white"/>
                </Col>
                <hr className="hide-desktop"/>
                <Col className="footer-col-wrapper" lg={6} md={12}>
                  <Row>
                    <FooterColumn title="Entdecke Lylli" lg={4} md={4} sm={6} xs={6}>
                      <TextLink to='/titel-suchen'>
                        Titel suchen
                      </TextLink>
                      <TextLink to="/kategorien">
                        Kategorien
                      </TextLink>
                      <TextLink to="/hoerbuecher">
                        Hörbücher
                      </TextLink>
                      <TextLink to="/register/create-account?referrer_type=home&referrer_object=main&referrer_element=footer">
                        Konto erstellen
                      </TextLink>
                      <TextLink external to="https://account.lylli.de/">
                        Mein Konto
                      </TextLink>
                      <TextLink to="/lust-aufs-lesen">
                        Tipps und Inspiration
                      </TextLink>
                      <TextLink to="/veroffentlichen">
                        Für Autoren/Verleger
                      </TextLink>
                      <TextLink external to="https://shop.lylli.de/">
                        Lylli Shop
                      </TextLink>
                    </FooterColumn>

                    <Col className="footer-col" lg={4} md={4} sm={6} xs={6}>
                      <FooterColumn title="Lerne uns kennen" lg={12} sm={12} xs={12}>
                        <TextLink to='/uber'>
                          Über uns
                        </TextLink>
                        <TextLink external to='https://presse.lylli.de/'>
                          Presse
                        </TextLink>
                        <TextLink to='/impressum'>
                          Impressum
                        </TextLink>
                      </FooterColumn>

                      <FooterColumn title="Folge uns" lg={12} sm={12} xs={12}>
                        <div className='footer-socials-container'>
                          <ImgLink to="https://facebook.com/lylli.appen" classname="social-logo-link" src={iconFB}/>
                          <ImgLink to="https://www.instagram.com/lylli_de/" classname="social-logo-link" src={iconIN}/>
                          <ImgLink to="https://www.linkedin.com/company/lylli/" classname="social-logo-link" src={iconLI}/>
                        </div>
                      </FooterColumn>  
                    </Col>

                    <Col className="footer-col" lg={4} md={4} sm={6} xs={6}>
                      <FooterColumn title="Hilfe und Kontakt" lg={12} sm={12} xs={12}>
                        <TextLink to='/fragen-und-antworten'>
                          Fragen und Antworten
                        </TextLink>
                        <TextLink external to='mailto:kontakt@lylli.de'>
                          Schicke uns eine Mail
                        </TextLink>
                      </FooterColumn>
                      <FooterColumn title="Sprache" lg={12} sm={12} xs={12}>
                        <ImgLink to={SWE_SITE_URL} classname="flag-icon-link" src={sweIcon} text="Schwedisch"/>
                      </FooterColumn> 
                    </Col>            
                  </Row>
                </Col>
              </Row>
              <hr/>
              <Row className="footer-row">
                <Col className='footer-col-wrapper' lg={6} md={12} sm={12}>
                  <div className='footer-policy-column'>
                    <TextLink to='/nutzungsbedingungen'>
                      Nutzungsbedingungen
                    </TextLink>
                    <div className='vl'></div> 
                    <TextLink to='/cookies'>
                      Cookies
                    </TextLink>
                    <div className='vl'></div> 
                    <TextLink to='/datenschutzerklarung'>
                      Datenschutzerklärung
                    </TextLink>
                    <div className='vl'></div> 
                    <p>
                      @ 2024 Boksnok AB
                    </p>
                  </div>
                </Col>

                <Col className='footer-col-wrapper' lg={6} md={6} sm={12}>
                  <div className='footer-payment-column'>
                    <PayIcons />
                  </div>
                </Col>
              </Row>
            </Container>
            }
        </div>
      </footer>
  );
};
