import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';

import './Section.css';
import { Colors } from "../../layout";

export default ({ children, className, bgColor }) => (
  <section
    className={className}
    style={{
      backgroundColor: bgColor ? bgColor : Colors.white,
    }}
  >
    <Jumbotron>
      <Container>{children}</Container>
    </Jumbotron>
  </section>
);
