import React from "react"
import Col from "react-bootstrap/Col"

export default ({ children, title, lg, md, sm, xs }) => {
    return (
      <Col xs={xs} md={md} lg={lg} sm={sm} className="footer-col">
        <div style={{ margin: "auto", display: "inline-block" }}>
          <div style={{ margin: "auto", textAlign: "left" }}>
            <h4 className="footer-row-header">{title}</h4>
            <div className="footer-row-body">{children}</div>
          </div>
        </div>
      </Col>
    )
}
