import React from 'react';
import PropTypes from 'prop-types';

import appStoreDeu from '../../images/appLogos/appstore-de.png';
import appStoreSwe from '../../images/appLogos/appstore.png';
import googlePlaySwe from '../../images/appLogos/googleplay.png';
import googlePlayDeu from '../../images/appLogos/google-play-badge.svg';
import appStoreLightSwe from '../../images/appLogos/appstore.svg';
import appStoreLightDeu from '../../images/appLogos/appstore-deu-light.svg';
import googlePlayLightSwe from '../../images/appLogos/google.svg';
import googlePlayLightDeu from '../../images/appLogos/googleplay-deu-light.svg';
import amazonAppStoreLight from '../../images/appLogos/amazon-appstore-badge-de-white.svg';
import amazonAppStore from '../../images/appLogos/amazon-appstore-badge-de-black.svg';
import appStoreSweWhite from '../../images/appLogos/AppstoreWhite.svg';
import googlePlaySweWhite from '../../images/appLogos/GooglePlayWhite.svg';
import appStoreWhiteDeu from '../../images/appLogos/appstoreWhiteDeu.svg';
import googlePlayWhiteDeu from '../../images/appLogos/googlePlayWhiteDeu.svg';
import amazonAppStoreWhite from '../../images/appLogos/amazonWhiteDeu.svg';

const { STORELOGOS } = require('../../../config');

const IMAGES = {
  SWE: {
    APPLE: {
      default: appStoreSwe,
      light: appStoreLightSwe,
      white: appStoreSweWhite, 
    },
    GOOGLE: {
      default: googlePlaySwe,
      light: googlePlayLightSwe,
      white: googlePlaySweWhite,
    },
  },
  DEU: {
    APPLE: {
      default: appStoreDeu,
      light: appStoreLightDeu,
      white: appStoreWhiteDeu,
    }, 
    GOOGLE: {
      default: googlePlayDeu,
      light: googlePlayLightDeu,
      white: googlePlayWhiteDeu,
    },
    AMAZON: {
      default: amazonAppStore,
      light: amazonAppStoreLight,
      white: amazonAppStoreWhite,
    }
  }
}

const AppLogo = ({ className, region, trackerName, appType, theme }) => {
  const appLogo = STORELOGOS[appType];
  if(appLogo){
    return (
      <a
        id={`${trackerName}${appLogo.linkIdSuffix}`}
        href={appLogo.href}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          alt={appLogo.alt}
          className={className}
          id={`${trackerName}${appLogo.imageIdSuffix}`}
          src={theme ? IMAGES[region][appType][theme] : IMAGES[region][appType]['default']}
        />
      </a>
    );
  } else {
    return <></>;
  }
  
};

AppLogo.propTypes = {
  className: PropTypes.string,
  trackerName: PropTypes.string.isRequired,
  type: PropTypes.string,
  region: PropTypes.string
};

export default AppLogo;
